// @flow

import React from "react";
import {
    RequestDetails,
    DashboardLayout,
    Layout,
    withPrivateRoute,
} from "@containers";

/**
 * Assignment Preparation
 */
const AanvraagDetails = (props: *) => (
    <Layout {...props}>
        <DashboardLayout title="Aanvraag details">
            <RequestDetails
                location={props.location}
                requestId={props.requestId}
            />
        </DashboardLayout>
    </Layout>
);

export default withPrivateRoute(
    AanvraagDetails,
    "/aanvragen/:requestId/aanvraag-details",
);
